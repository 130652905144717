import React from 'react'
import { Lock } from 'react-feather';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #fff;

  background: linear-gradient(39deg, #ff5100, #ff0000);
`;

const NotAuthenticated = ({history}) => {
    return (
        <ErrorContainer>
            <Lock size={120} />
          <h1 style={{textAlign: "center", textTransform: "uppercase"}}>
            Vous devez être connecté<br/> pour accéder à cette page !
          </h1>
          <p>Cliquez sur le bouton ci-dessous pour retourner la page de connexion</p>
          <Button icon="lock" size="large" color="black" onClick={() => history.replace("/")}>
            Se Connecter
          </Button>
        </ErrorContainer>
      );
}

export default NotAuthenticated
