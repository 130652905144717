import moment from "moment";

export const checkSessionActivityTime = () => {
  //recupération du _SESS_EXP_TIME si disponible
  const lastConnexionTime = localStorage.getItem("sess_tr");
  if (typeof lastConnexionTime !== "undefined" && lastConnexionTime) {
    const currentTime = moment(new Date()).unix() - lastConnexionTime;
    return parseInt(currentTime);
  } else {
    return -1;
  }
};