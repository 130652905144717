import React from "react";
import styled from "styled-components";

const AuthContainer = styled.div`
  box-sizing: border-box;
  margin: 0px;
  overflow: hidden;
`;

const AuthLayout = ({ children, ...props}) => {
  // const getRoutes = (routes) => {
  //   return routes.map((prop, key) => {
  //     if (prop.layout === "/auth") {
  //       return (
  //         <Route
  //           path={encodeURI(prop.path)}
  //           component={prop.component}
  //           key={key}
  //         />
  //       );
  //     } else {
  //       return null;
  //     }
  //   });
  // };
  return (
    <AuthContainer>
      {children}
    </AuthContainer>
  );
};

export default AuthLayout;
