import React, {useState} from 'react'
import styled, { css } from 'styled-components'
import ProfileImg from '../../assets/profile-img.gif'

const UserMenuVisible = css`
    transition: all 0.4s ease-in-out;
    position: absolute;
    display: flex;
    transform: translateX(0px);
    top: 60px;
    height: 250px;
    width: 150px;
    right: 0;
    background: #FFF;
    border-radius: 5px;
`

const UserMenuHidden = css`
    position: absolute;
    display: none;
    transition: all 0.4s ease-in-out;
    transform: translateX(200px);
    top: 60px;
    height: 250px;
    width: 150px;
    background: #FFF;
    border-radius: 5px;
`

const AvatarContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 50px;
`

const AvatarImg = styled.img`
    height: 40px;
    width: 40px;
    border-radius: 50px;
    padding: 3px;
    background: #FFFFFF;
    /* background: #FFFFFF; */
    box-shadow: 0px 3px 15px #00000010;
    cursor: pointer;
`
const UserMenu = styled.div`
    ${props => props.displayMenu === true ? UserMenuVisible : UserMenuHidden}
`

const Avatar = () => {
    const [userMenuToggled, setUserMenuToggled] = useState(false)
    //Afficher ou Cacher le Menu utilisateur
    const toggleUserMenu = () => {
        setUserMenuToggled(userMenuToggled => setUserMenuToggled(!userMenuToggled))
    }

    return (
        <AvatarContainer>
            <AvatarImg src={ProfileImg} alt="User Image"/>
            <UserMenu displayMenu={userMenuToggled}>
                test
            </UserMenu>
        </AvatarContainer>
    )
}

export default Avatar
