import axios from "axios";
import moment from "moment";
import cookie from "react-cookies";
import { checkSessionActivityTime } from "../../utils/SessionHelper";
import {
  API_URL,
  CONNEXION_URL,
  EDIT_PASSWORD_URL,
} from "../config/constantes";
import CryptoJS from "crypto-js";
import sha256 from "crypto-js/sha256";

export const login = async (data) => {
  try {
    const res = await axios.post(`${API_URL + CONNEXION_URL}`, data, {
      timeout: 30000,
      timeoutErrorMessage: "La durée de la requête a expirée",
    });

    if (res.data.statut === "succes") {
      //On enregistre le token
      //generation de la clé d'encryptage de la compagnie
      const expires = new Date();
      expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 14);
      res.data.resultat["hash_compagnie"] = CryptoJS.AES.encrypt(
        res.data.resultat.raison_social_company,
        "fyle_group"
      ).toString();
      console.log(res.data.resultat);
      let string = JSON.stringify(res.data.resultat);
      cookie.save(
        "fg_etiq_tok",
        CryptoJS.AES.encrypt(string, "fg_etiquetage").toString(),
        {
          path: "/",
          expires,
          secure: false,
        }
      );
      cookie.save("fg_user_tok", res.data.resultat.token_user, {
        path: "/",
        expires,
        secure: false,
      });
      cookie.save("fg_auth_tok", res.data.accessToken, {
        path: "/",
        expires,
        secure: false,
      });
      localStorage.setItem("logged", 1);
      localStorage.setItem("sess_tr", moment(new Date()).unix());
    }
    // window.localStorage.setItem("erc-user-access-token", res.data.accessToken);
    // window.localStorage.setItem(
    //   "erc-user-auth-token",
    //   res.data.resultat.token_user
    // );
    window.localStorage.setItem("_SESS_EXP_TIME", moment(new Date()).unix());
    return res.data;
    // document.cookie=`erc-user-access-token=${res.data.accessToken};samesite=lax;secure=false`
    // document.cookie=`erc-user-auth-token=${res.data.resultat.token_user};samesite=lax;secure=false`

    // document.cookie=`erc-user-access-token=${res.data.accessToken};domain=lgiercinn.fyle-group.com;samesite=lax;secure=true`
    // document.cookie=`erc-user-auth-token=${res.data.resultat.token_user};domain=lgiercinn.fyle-group.com;samesite=lax;secure=true`

    // axios.defaults.headers["authorization"] = `Bearer ${res.data.accessToken}`;
    // localStorage.setItem("erc_a_u_d", JSON.stringify(res.data.resultat));

    // if (setHistoriqueAuthorization(res.data.resultat.token_user)) {
    //   history.replace("/tableau-de-bord");
    //   return res.data;
    // }
  } catch (error) {
    if (typeof error.response !== "undefined") {
      return { statut: "erreur", message: error.response.data.message };
    } else {
      return { statut: "erreur", message: error.message };
    }
  }
};

//Recupérer les infos d'un utilisateur
// export const getUserData = async () => {
//   const userData = await JSON.parse(localStorage.getItem("erc_a_u_d"));

//   if (getCookie(cookieName) && userData) {
//     return {
//       userData,
//     };
//   }
// };

export const getUserData = async () => {
  const userCookie = await cookie.load("fg_etiq_tok");
  // Si on a un cookie qui existe
  if (typeof userCookie != "undefined") {
    //decodage du cookie
    const time = checkSessionActivityTime();
    if (time > 172800) {
      cookie.remove("fg_etiq_tok", { path: "/" });
      cookie.remove("fg_auth_tok", { path: "/" });
      cookie.remove("fg_user_tok", { path: "/" });
    } else {
      // console.log(userCookie);
      localStorage.setItem("logged", 1);
      // console.log(JSON.parse(atob(userCookie)));
      let string = CryptoJS.AES.decrypt(userCookie, "fg_etiquetage");
      return JSON.parse(string.toString(CryptoJS.enc.Utf8));
    }
  } else {
    localStorage.clear();
    return undefined;
  }
};

export const logout = (history) => {
  // document.cookie = "erc-user-access-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  // document.cookie = "erc-user-auth-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  cookie.remove("fg_etiq_tok", { path: "/" });
  cookie.remove("fg_auth_tok", { path: "/" });
  cookie.remove("fg_user_tok", { path: "/" });
  localStorage.clear();
  history.replace("/");
};

export const logoutSession = (history) => {
  // document.cookie = "erc-user-access-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  // document.cookie = "erc-user-auth-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  cookie.remove("fg_etiq_tok", { path: "/" });
  cookie.remove("fg_auth_tok", { path: "/" });
  cookie.remove("fg_user_tok", { path: "/" });
  localStorage.clear();
  history.replace("/");
};

export const editUserPassword = async (data) => {
  try {
    //Récupération de la photo si possible
    const res = await axios.post(`${API_URL + EDIT_PASSWORD_URL}`, data);
    return res.data;
  } catch (error) {
    return { statut: "erreur", message: error.response.data.message };
  }
};
