import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    html{
        scroll-behavior: smooth   
    }
    ul li {
        list-style-type: none;
        display: block;
    }
`;

export default GlobalStyles;
