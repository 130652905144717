import React, { useState } from "react";
import { Switch } from "react-router-dom";
import styled from "styled-components";
import { AdminNavbar } from "../components/Navbar/Navbar";
import PrivateRoute from "../components/PrivateRoute";
import Sidebar from "../components/Sidebar/Sidebar";
import routes from "../routes/routesConfig";
import sidebarRoutes from "../routes/sidebarRoutesConfig";

const AdminContainer = styled.div`
  box-sizing: border-box;
  margin: 0px;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;

  //Styliser la barre scroll de l'app
  /* Style Pour Firefox */
  scrollbar-width: thin;
  scrollbar-color: #f7f7f7 #322D6A;
  /* Style Pour Chrome, Edge et Safari */
  &::-webkit-scrollbar {
    width: 0.8rem;
  }
  &::-webkit-scrollbar-track {
    background: #f7f7f7;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 20px;
    border: 3px solid #f7f7f7;
    &:hover {
      background-color: #322D6A;
    }
  }
`;
const PageContentWrapper = styled.div`
  flex: 1;
  /* margin-left: 220px; */
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  margin-left: ${(props) => (props.toggled === true ? "0px" : "220px")};
  background: #f7f7f7;
  /* background: #0F0F0F40; */
  padding-bottom: 1.5rem;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: calc(100vh - 60px);
  background: red;
`;
const AdminLayout = ({children, ...props}) => {
  //Toggle the sidebar on desktop screens
  const [sidebarToggled, setSidebarToggled] = useState(false);

  const toggleSidebar = () => {
    setSidebarToggled((sidebarToggled) => setSidebarToggled(!sidebarToggled));
  };

  //Recupération du systeme de routage
  // const getRoutes = (routes) => {
  //   return routes.map((route, key) => {
  //     if (route.layout === "/admin") {
  //       return (
  //         //TODO: Verifier si l'utilisateur est connecté et a les permissions d'accéder à la page
  //         <PrivateRoute
  //           path={route.path}
  //           component={route.component}
  //           key={key}
  //           exact={true}
  //         />
  //       );
  //     } else {
  //       return null;
  //     }
  //   });
  // };
  return (
    <AdminContainer>
      {/* Navbar Menu Here */}
      <AdminNavbar
        changeSidebarVisibility={toggleSidebar}
        toggled={sidebarToggled}
        history={props.history}
      />
      <ContentWrapper>
        {/* <div>5</div>
        <div>58</div> */}
        {/* Sidebar Menu over here */}
        <Sidebar
          changeSidebarVisibility={toggleSidebar}
          toggled={sidebarToggled}
          sidebarRoutes={sidebarRoutes}
          {...props}
        />
        <PageContentWrapper toggled={sidebarToggled}>
          {children}
          {/* {<Switch>{getRoutes(routes)}</Switch>} */}
        </PageContentWrapper>
      </ContentWrapper>
    </AdminContainer>
  );
};

export default AdminLayout;
