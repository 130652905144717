import React, { useEffect } from "react";
import { Download, Menu } from "react-feather";
import { toast } from "react-semantic-toasts";
import { Dropdown } from "semantic-ui-react";
import styled from "styled-components";
import { logout, logoutSession } from "../../data/auth/authActions";
import useUser from "../../data/auth/use-user";
import {
  checkSessionActiveTime,
  getCookie,
  setAxiosAuthorization,
  setHistoriqueAuthorization,
} from "../../utils/HelpersFunctions";
import Avatar from "../Avatar/Avatar";
import LogoApp from "../../assets/logo_fyle.png";
import AppErcinn from "../../assets/com.ercinn.apk";

const DownloadContainer = styled.div`
  transition: all 0.3s ease-in;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0.1rem 1rem;
  background-color: #fff;
  border-radius: 5px;

  &:hover {
    transition: all 0.3s ease-in;
    cursor: pointer;
    border: none;
    box-shadow: 0px 0px 10px #00000010;
  }

  @media screen and (max-width: 700px) {
    transition: all 0.3s ease-in;
    display: none;
  }
`;
const AppHeader = styled.div`
  width: max-content;
  display: flex;
  flex-direction: row;
  /* padding: 0px 10px; */
  justify-content: space-between;
  /* align-items: center; */
  /* background-color: red; */
`;
const NavbarContainer = styled.div`
  transition: left 0.4s ease-out;
  margin: 0px;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  box-shadow: 0px 3px 15px #00000010;
  padding-left: 15px;
  padding-right: 15px;
  position: sticky;
  top: 0px;
  /* left: ${(props) => (props.sidebarToggled === true ? "65px" : "200px")}; */
  right: 0px;
  z-index: 110;
  background: #fff;
  width: 100%;

  @media screen and (max-width: 600px) {
    /* left: ${(props) => (props.sidebarToggled === true ? "0px" : "200px")}; */
    transition: left 0.4s ease-out;
    position: absolute;
  }
`;

const TogglerButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;
const UserMenuItem = styled.li`
  transition: all 0.3s ease-in-out;
  @media screen and (max-width: 600px) {
    &:first-child{
      display: none;
      transition: all 0.3s ease-in-out;
    }
  }
`;
const MenuIcon = styled(Menu)`
  color: #332e6f;
`;

const UserMenuContainer = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;

  li {
    list-style-type: none;
    padding-left: 0.8em;
  }
`;
const UserIdCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  & > :first-child {
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: -5px;
    color: #312b68;
  }

  & > :nth-child(2) {
    color: red;
    font-size: 12px;
  }
`;

const AppLogoIcon = styled.img`
  width: 30px;
`;

const AppLogoName = styled.div`
  color: #322d6a;
  margin-top: 2px;
  font-size: 25px;
  font-weight: bold;
`;

export const AdminNavbar = (props) => {
  const { user, chargement, isConnected, erreur } = useUser();
  const userMenuOptions = [
    {
      key: "wel",
      text: (
        <>
          <span>
            {user && user.nom_user + " " + user.prenom_user}
            <br />
            {user && user.email_user}
          </span>
        </>
      ),
      icon: "",
      value: 0,
    },
    {
      key: "user",
      text: (
        <span onClick={() => props.history.push("/modifier-mot-de-passe")}>
          Modifier mon mot de passe
        </span>
      ),
      icon: "user",
      value: 1,
    },
    {
      key: "sign-out",
      text: <span onClick={() => logout(props.history)}>Déconnexion</span>,
      icon: "sign out",
      value: 2,
    },
  ];

  // useEffect(() => {
  //   setupToken();
  // }, [user]);

  useEffect(() => {
    let time = checkSessionActiveTime();
    // console.log(time);
    //si il est connecté depuis plus d'un jour
    if (time > 172800) {
      toast({
        title: "Session Expirée",
        type: "info",
        size: "small",
        description: <p>Votre session a expirée veuillez vous reconnecté</p>,
      });
      logoutSession(props.history);
      console.log(time);
    }
  }, []);

  // useEffect(() => {
  //     window.addEventListener('beforeunload', () => localStorage.clear())
  //   }, [])

  const setupToken = () => {
    //1. voir si on a un token
    const cookieName = "erc-user-access-token";
    const userCookieName = "erc-user-auth-token";

    const tokenAccess = getCookie(cookieName);
    const tokenUser = getCookie(userCookieName);

    //Voir si le token existe
    if (tokenAccess && tokenUser) {
      setAxiosAuthorization(tokenAccess);
      setHistoriqueAuthorization(tokenUser);
      return true;
    } else {
      if (isConnected !== true) {
        logoutSession(props.history);
      }
    }
  };

  //Télécharger l'appli mobile
  const downloadApp = () => {
    window.open(`${AppErcinn}`, "_blank");
  };

  return (
    <NavbarContainer sidebarToggled={props.toggled}>
      <AppHeader>
        {/* <AppLogoIcon src={LogoApp} alt="AppLogo" /> */}
        <TogglerButton onClick={props.changeSidebarVisibility}>
          <MenuIcon />
        </TogglerButton>
        <AppLogoName>{user && user.raison_social_company}</AppLogoName>
        &nbsp;
      </AppHeader>
      {/* <DownloadContainer onClick={downloadApp}>
        <Download size={38} />
        &nbsp;
        <div>
          <div>
            <span style={{ color: "#322D6A", fontWeight: 700 }}>
              Télécharger l'application
            </span>
          </div>
          <div>
            <span style={{ color: "#322D6A" }}>Ercinn Etiquetage</span>
          </div>
        </div>
      </DownloadContainer> */}
      <UserMenuContainer>
        <UserMenuItem>
          <UserIdCard>
            <div>{user && user.nom_user + " " + user.prenom_user}</div>
            <div>{user && user.email_user}</div>
          </UserIdCard>
        </UserMenuItem>
        <UserMenuItem>
          <>
            <Dropdown
              direction="left"
              style={{ display: "flex", alignItems: "flex-start" }}
              pointing="top left"
              inline
              trigger={<Avatar />}
              options={userMenuOptions}
              icon={null}
              defaultValue={0}
              onChange={(e, { value }) => {}}
            />
          </>
        </UserMenuItem>
      </UserMenuContainer>
    </NavbarContainer>
  );
};
