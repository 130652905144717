import "cropperjs/dist/cropper.css";
import "moment/locale/fr";
import React from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import "semantic-ui-css/semantic.min.css";
import "./App.css";
import GlobalStyles from "./GlobalStyles";
import AppRouter from "./routes/router";

function App() {
  return (
    <>
      <GlobalStyles />
      <AppRouter />
    </>
  );
}

export default App;
