import React from "react";
import styled from "styled-components";

const MediumContainer = styled.div`
  box-sizing: border-box;
  margin: 0px;
  overflow: hidden;
`;

const MediumLayout = ({ children, ...props }) => {
  return <MediumContainer>{children}</MediumContainer>;
};

export default MediumLayout;
