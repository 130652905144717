import { lazy } from "react";
import {
  Anchor,
  Box,
  Check,
  Code,
  FileMinus,
  FilePlus,
  Framer,
  Grid,
  Home,
  Lock,
  Map,
  MapPin,
  Settings,
  Sliders,
  UserPlus,
  Users,
  ZoomIn,
} from "react-feather";

//
const Dashboard = lazy(() => import("../pages/Admin/Dashboard/Dashboard"));
const ListeMateriel = lazy(() =>
  import("../pages/Admin/Materiel/ListeMateriel")
);
const ListeCategoriesMateriels = lazy(() =>
  import("../pages/Admin/Materiel/ListeCategorieMateriel")
);
const ListeBureau = lazy(() => import("../pages/Admin/Bureau/ListeBureau"));
const ListeServices = lazy(() =>
  import("../pages/Admin/Service/ListeServices")
);
const ListeRoles = lazy(() => import("../pages/Admin/roles/ListeRoles"));
const ListeFonctions = lazy(() =>
  import("../pages/Admin/Fonction/ListeFonctions")
);
const ListeUtilisateur = lazy(() =>
  import("../pages/Admin/Utilisateur/ListeUtilisateur")
);
const Etiquettage = lazy(() =>
  import("../pages/Admin/Etiquettage/Etiquettage")
);
const Verification = lazy(() =>
  import("../pages/Admin/Verification/Verification")
);
const ListeLieuxTravaux = lazy(() =>
  import("../pages/Admin/Lieux/ListeLieuxTravaux")
);
const ListeSousCategorieMateriel = lazy(() =>
  import("../pages/Admin/Materiel/ListeSousCategorieMateriel")
);

/*
  Administrateur => 1
  Etiqueteur => 2
  Gestionnaire => 4
  Employer => 3
*/

const sidebarRoutes = [
  {
    name: "Tableau de Bord",
    path: "/tableau-de-bord",
    component: Dashboard,
    icon: Grid,
    icon_type: "",
    layout: "/admin",
    permissions: [1, 2, 3],
  },
  {
    name: "Vérification",
    path: "/verification",
    component: Verification,
    icon: Check,
    icon_type: "",
    layout: "/admin",
    permissions: [1, 2],
  },
  {
    name: "Etiquettage",
    path: "/etiquettage",
    component: Etiquettage,
    icon: ZoomIn,
    icon_type: "",
    layout: "/admin",
    permissions: [1, 2],
  },
  {
    name: "Mod. Matériels",
    path: "/materiels",
    component: ListeMateriel,
    icon: Box,
    icon_type: "",
    layout: "/admin",
    permissions: [1, 2],
  },
  {
    name: "Cat. Matériels",
    path: "/categories-materiels",
    component: ListeCategoriesMateriels,
    icon: FileMinus,
    icon_type: "",
    layout: "/admin",
    permissions: [1, 2],
  },
  {
    name: "S.Cat Matériels",
    path: "/sous-categories-materiels",
    component: ListeSousCategorieMateriel,
    icon: FilePlus,
    icon_type: "",
    layout: "/admin",
    permissions: [1, 2],
  },
  {
    name: "Paramètres",
    icon: Settings,
    icon_type: "",
    layout: "/admin",
    permissions: [1],
    children: [
      {
        name: "Utilisateurs",
        path: "/utilisateurs",
        component: ListeUtilisateur,
        icon: Users,
        icon_type: "",
        layout: "/admin",
        permissions: [1],
      },
      {
        name: "Lieux Travaux",
        path: "/lieux-travaux",
        component: ListeLieuxTravaux,
        icon: Anchor,
        icon_type: "",
        layout: "/admin",
        permissions: [1],
      },
      {
        name: "Bureaux",
        path: "/bureaux",
        component: ListeBureau,
        icon: Home,
        icon_type: "",
        layout: "/admin",
        permissions: [1],
      },
      {
        name: "Services",
        path: "/services",
        component: ListeServices,
        icon: Map,
        icon_type: "",
        layout: "/admin",
        permissions: [1],
      },
      {
        name: "Fonctions",
        path: "/fonctions",
        component: ListeFonctions,
        icon: Sliders,
        icon_type: "",
        layout: "/admin",
        permissions: [1],
      },

      {
        name: "Rôles",
        path: "/gestion-roles",
        component: ListeRoles,
        icon: Lock,
        icon_type: "",
        layout: "/admin",
        permissions: [1],
      },
    ],
  },
];

export default sidebarRoutes;
