import React from "react";
import { Sunset } from "react-feather";
import { Button } from "semantic-ui-react";
import styled from "styled-components";
const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #fff;

  background: linear-gradient(39deg, #414141, #000000);
`;
const NotFound = ({ history, location }) => {
  return (
    <ErrorContainer>
        <Sunset size={120} />
        <h1><kbd>Erreur 4🌚4</kbd></h1>
      <h1 style={{ textAlign: "center", textTransform: "uppercase" }}>
        Hum!! La page que vous essayez d'atteindre <br/>n'existe pas 
      </h1>
      <Button size="large" color="red" onClick={() => history.goBack()}>
        Retour à la page précédente
      </Button>
    </ErrorContainer>
  );
};

export default NotFound;
