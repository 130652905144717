import { action } from "easy-peasy";

const storeModel = {
  app_menu_state: { menu_visibility: true, children_selected: false },
  changeMenuState: action((state, payload) => {
    state.app_menu_state = { menu_visibility: payload };
  }),
  toggleChildrenMenuState: action((state, payload) => {
    state.app_menu_state = { children_selected: payload };
  }),
};

export default storeModel;
