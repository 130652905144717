import { lazy } from 'react'

//
const Dashboard = lazy(() => import('../pages/Admin/Dashboard/Dashboard'))
const ListeMateriel = lazy(() => import('../pages/Admin/Materiel/ListeMateriel'))
const ListeCategoriesMateriels = lazy(() => import('../pages/Admin/Materiel/ListeCategorieMateriel'))
const ListeSousCategorieMateriel = lazy(() => import('../pages/Admin/Materiel/ListeSousCategorieMateriel'))
const ListeBureau = lazy(() => import('../pages/Admin/Bureau/ListeBureau'))
const ListeServices = lazy(() => import('../pages/Admin/Service/ListeServices'))
const ListeFonctions = lazy(() => import('../pages/Admin/Fonction/ListeFonctions'))
const ListeRoles = lazy(() => import('../pages/Admin/roles/ListeRoles'))
const ListeUtilisateur = lazy(() => import('../pages/Admin/Utilisateur/ListeUtilisateur'))
const ListeLieuxTravaux = lazy(() => import('../pages/Admin/Lieux/ListeLieuxTravaux'))
const Etiquettage = lazy(() => import('../pages/Admin/Etiquettage/Etiquettage'))
const Verification = lazy(() => import('../pages/Admin/Verification/Verification'))
const Login = lazy(() => import('../pages/Auth/Login/Login'))
const EditPassword = lazy(() => import('../pages/Auth/Password/EditPassword'))
const AjouterUtilisateur = lazy(() => import('../pages/Admin/Utilisateur/AjouterUtilisateur'))
const AjouterService = lazy(() => import('../pages/Admin/Service/AjouterService'))
const AjouterRole = lazy(() => import('../pages/Admin/roles/AjouterRole'))
const AjouterFonction = lazy(() => import('../pages/Admin/Fonction/AjouterFonction'))
const AjouterBureau = lazy(() => import('../pages/Admin/Bureau/AjouterBureau'))
const AjouterLieuTravail = lazy(() => import('../pages/Admin/Lieux/AjouterLieu'))
const AjouterCategorieMateriel = lazy(() => import('../pages/Admin/Materiel/AjouterCategorieMateriel'))
const AjouterSousCategorieMateriel = lazy(() => import('../pages/Admin/Materiel/AjouterSousCategorieMateriel'))
const AjouterMateriel = lazy(() => import('../pages/Admin/Materiel/AjouterMateriel'))
const ModifierBureau = lazy(() => import('../pages/Admin/Bureau/ModifierBureau'))
const ModifierLieu = lazy(() => import('../pages/Admin/Lieux/ModifierLieu'))
const ModifierFonction = lazy(() => import('../pages/Admin/Fonction/ModifierFonction'))
const ModifierService = lazy(() => import('../pages/Admin/Service/ModifierService'))
const ModifierRole = lazy(() => import('../pages/Admin/roles/ModifierRole'))
const ModifierCategorieMateriel = lazy(() => import('../pages/Admin/Materiel/ModifierCategorieMateriel'))
const ModifierSousCategorieMateriel = lazy(() => import('../pages/Admin/Materiel/ModifierSousCategorieMateriel'))
const ModifierMateriel = lazy(() => import('../pages/Admin/Materiel/ModifierMateriel'))
const ModifierPhotoMateriel = lazy(() => import('../pages/Admin/Materiel/ModifierPhotoMateriel'))
const ModifierUtilisateur = lazy(() => import('../pages/Admin/Utilisateur/ModifierUtilisateur'))
const ModifierMotDePasse = lazy(() => import('../pages/Admin/Utilisateur/ModifierMotDePasse'))

const routes = [
    {
        name: "Tableau de Bord",
        path: "/tableau-de-bord",
        component: Dashboard,
        layout: "/admin",
        permissions: [1, 2, 3],
    },
    {
        name: "Utilisateurs",
        path: "/utilisateurs",
        component: ListeUtilisateur,
        layout: "/admin",
        permissions: [1],
    },
    {
        name: "creation-utilisateur",
        path: "/utilisateurs/creer-utilisateur",
        component: AjouterUtilisateur,
        layout: "/admin",
        permissions: [1],
    },
    {
        name: "modifier-utilisateur",
        path: "/utilisateurs/modifier-utilisateur/:idUtilisateur",
        component: ModifierUtilisateur,
        layout: "/admin",
        permissions: [1],
    },
    {
        name: "Etiquettage",
        path: "/etiquettage",
        component: Etiquettage,
        layout: "/admin",
        permissions: [1, 2],
    },
    {
        name: "Vérification",
        path: "/verification",
        component: Verification,
        layout: "/admin",
        permissions: [1, 2],
    },
    {
        name: "Matériels",
        path: "/materiels",
        component: ListeMateriel,
        layout: "/admin",
        permissions: [1],
    },
    {
        name: "Matériels",
        path: "/materiels/modifier-materiel/:idMat",
        component: ModifierMateriel,
        layout: "/admin",
        permissions: [1],
    },
    {
        name: "Modifier Matériels",
        path: "/materiels/modifier-photo-materiel/:idMat",
        component: ModifierPhotoMateriel,
        layout: "/admin",
        permissions: [1, 2],
    },
    {
        name: "Ajouter Materiel",
        path: "/materiels/creer-materiel",
        component: AjouterMateriel,
        layout: "/admin",
        permissions: [1],
    },
    {
        name: "Catégories Matériels",
        path: "/categories-materiels",
        component: ListeCategoriesMateriels,
        layout: "/admin",
        permissions: [1, 2],
    },
    {
        name: "Ajouter Categorie Materiel",
        path: "/categories-materiels/creer-categorie-materiel",
        component: AjouterCategorieMateriel,
        layout: "/admin",
        permissions: [1],
    },
    {
        name: "Modifier Categorie Materiel",
        path: "/categories-materiels/modifier-categorie-materiel/:idCat",
        component: ModifierCategorieMateriel,
        layout: "/admin",
        permissions: [1,],
    },
    {
        name: "Sous Categories",
        path: "/sous-categories-materiels",
        component: ListeSousCategorieMateriel,
        layout: "/admin",
        permissions: [1, 2],
    },
    {
        name: "Ajouter sous Categorie Materiel",
        path: "/sous-categories-materiels/creer-sous-categorie-materiel",
        component: AjouterSousCategorieMateriel,
        layout: "/admin",
        permissions: [1],
    },
    {
        name: "Modifier sous-Categorie Materiel",
        path: "/sous-categories-materiels/modifier-sous-categorie-materiel/:idSousCat",
        component: ModifierSousCategorieMateriel,
        layout: "/admin",
        permissions: [1],
    },
    {
        name: "Bureaux",
        path: "/bureaux",
        component: ListeBureau,
        layout: "/admin",
        permissions: [1, 2],
    },
    {
        name: "Ajouter Bureau",
        path: "/bureaux/creer-bureau",
        component: AjouterBureau,
        layout: "/admin",
        permissions: [1],
    },
    {
        name: "Modifier Bureau",
        path: "/bureaux/modifier-bureau/:idBureau",
        component: ModifierBureau,
        layout: "/admin",
        permissions: [1],
    },
    {
        name: "Services",
        path: "/services",
        component: ListeServices,
        layout: "/admin",
        permissions: [1, 2],
    },
    {
        name: "Ajout Service",
        path: "/services/creer-service",
        component: AjouterService,
        layout: "/admin",
        permissions: [1],
    },
    {
        name: "Modifier Service",
        path: "/services/modifier-service/:idService",
        component: ModifierService,
        layout: "/admin",
        permissions: [1],
    },
    {
        name: "Roles",
        path: "/gestion-roles",
        component: ListeRoles,
        layout: "/admin",
        permissions: [1],
    },
    {
        name: "Ajout Role",
        path: "/gestion-roles/creer-role",
        component: AjouterRole,
        layout: "/admin",
        permissions: [1],
    },
    {
        name: "Modifier Role",
        path: "/gestion-roles/modifier-role/:idRole",
        component: ModifierRole,
        layout: "/admin",
        permissions: [1],
    },
    {
        name: "Fonctions",
        path: "/fonctions",
        component: ListeFonctions,
        layout: "/admin",
        permissions: [1, 2],
    },
    {
        name: "Ajouter Fonction",
        path: "/fonctions/creer-fonction",
        component: AjouterFonction,
        layout: "/admin",
        permissions: [1],
    },
    {
        name: "Modifier Fonction",
        path: "/fonctions/modifier-fonction/:idFonction",
        component: ModifierFonction,
        layout: "/admin",
        permissions: [1],
    },
    {
        name: "Lieux Travaux",
        path: "/lieux-travaux",
        component: ListeLieuxTravaux,
        layout: "/admin",
        permissions: [1, 2],
    },
    {
        name: "Ajouter Lieu Travail",
        path: "/lieux-travaux/creer-lieu-travail",
        component: AjouterLieuTravail,
        layout: "/admin",
        permissions: [1],
    },
    {
        name: "Modifier Lieu Travail",
        path: "/lieux-travaux/modifier-lieu-travail/:idLieu",
        component: ModifierLieu,
        layout: "/admin",
        permissions: [1],
    },
    {
        name: "Modifier Mot de passe",
        path: "/modifier-mot-de-passe",
        component: ModifierMotDePasse,
        layout: "/admin",
        permissions: [1],
    },
    {
        name: "EditPassword",
        path: "/editpassword",
        component: EditPassword,
        layout: "/medium",
        permissions: [1, 2, 3],
    },
    {
        name: "Login",
        path: "/",
        component: Login,
        layout: "/auth",
        permissions: [],
    }
]

export default routes