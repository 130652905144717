import React from 'react'
import styled from 'styled-components'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import { Dimmer } from 'semantic-ui-react'

const LoaderContainer = styled.div`
    display: flex;
    height: 100vh;
    background: #312B68;
    justify-content: center;
    align-items: center;
`

export const LazySpinner = () => {
    return (
        <LoaderContainer>
            <Loader type="Circles" color="#FFF" height={80} width={80}/>
        </LoaderContainer>
    )
}

export const ContentLoader = ({isActif}) => (
    <Dimmer active={isActif}><Loader color='#FFF' size='large' content="Chargement en cours"/></Dimmer>
)

