import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStoreActions, useStoreState } from "easy-peasy";
import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useUser from "../../data/auth/use-user";

const SidebarContainer = styled.div`
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  height: 100%;
  position: fixed;
  width: ${(props) => (props.toggled === true ? "0px" : "220px")};
  background: ${(props) =>
    props.toggled === true
      ? "linear-gradient(45deg, #312B68, cyan)"
      : "linear-gradient(45deg, #312B68, cyan)"};
  z-index: 111;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  /* position: fixed; */
  /* overflow-x: none; */
  //Styliser la barre scroll de l'app

  overflow-y: auto;
  /* Style Pour Firefox */
  scrollbar-width: thin;
  scrollbar-color: #ffffff80 #efefef;
  /* Style Pour Chrome, Edge et Safari */
  /* &::-webkit-scrollbar {
      width: 0.8rem;
    }
    &::-webkit-scrollbar-track {
      background: #efefef;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #fff;
      border-radius: 20px;
      border: 3px solid #efefef;
      &:hover {
        background-color: #322d6a;
      }
    } */

  @media screen and (max-width: 600px) {
    /* display: none; */
    width: ${(props) => (props.toggled === true ? "0" : "100vh")};
    transition: all ease-out 0.5s;
    /* position: fix; */
    top: 60px;
    box-shadow: 3px 0px 15px #00000010;
  }
`;

const Tooltip = styled.div`
  display: block;
  transition: all 0.3s ease-out;
  position: absolute;
  padding: 5px 1rem;
  background: red;
  left: 100px;
`;

const NavItem = styled.div`
  /* background-color: orange; */
  position: relative;
  user-select: none;
  transition: all 0.2s ease-in;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  min-height: 50px;
  cursor: pointer;

  &:hover {
    transition: all 0.2s ease-out;
    background: #00000020;
    border-radius: 5px;

    /* ${Tooltip} {
      transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
      display: flex;
      height: inherit;
      width: 200px;
      right: -200px;
      box-shadow: 0px 0px 15px #00000050;
      z-index: 50000;
    } */
  }

  &:hover NavLink {
    color: #fff;
  }

  &:active {
    background: #00000020;
  }
`;

const NavLink = styled.span`
  display: flex;
  font-weight: 600;
  font-size: 15px;
  text-decoration: none;
  color: #fff;
  align-items: flex-end;

  &:hover {
  }
`;

const NavbarHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  height: 60px;
  margin-bottom: 10px;
  box-shadow: 0px 10px 15px #00000020;
  /* border-bottom: 2px solid #FFF; */
`;

const HeaderLink = styled(Link)`
  text-decoration: none;
`;

const AppLogoIcon = styled.img`
  width: 30px;
`;
const AppLogoName = styled.h2`
  color: #322d6a;
`;
const UlComponent = styled(motion.ul)`
  padding-left: 0px;
  background-color: ${(props) => props.isSubMenuOpen === true && "#322D6A"};
  /* border-left: 1px solid red; */
  &:nth-child(2) {
    border: none;
  }
  &:nth-child(2) li {
    padding-left: 0px;
  }

  /* ul {
    display: ${(props) => (props.isSubMenuOpen === true ? "block" : "none")};
  } */
`;
const SousMenu = styled(motion.li)``;

const sousMenuVariant = {
  visible: {
    display: "block",
    opacity: 1,
    x: 0,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.3,
    },
  },
  hidden: {
    opacity: 0,
    x: -100,
    display: "none",
    transition: {
      when: "afterChildren",
    },
  },
};

const itemVariant = {
  visible: (key) => ({
    display: "block",
    opacity: 1,
    x: 0,
    transition: { delay: key * 0.3 },
  }),
  hidden: { opacity: 0, x: -100, display: "none" },
};

const Sidebar = ({ history, ...props }) => {
  const childrenVisible = useStoreState(
    (state) => state.app_menu_state.children_selected
  );
  const { user } = useUser();

  // const toggleSubMenu = () => {
  //   setSubMenuState((subMenuState) => !subMenuState);
  // };
  const toggleSubMenu = useStoreActions(
    (actions) => actions.toggleChildrenMenuState
  );
  //Creation des liens
  const createLinks = (sidebarRoutes) => {
    return sidebarRoutes.map((prop, key) => {
      if (
        typeof user != "undefined" &&
        prop.permissions.includes(user.role_id)
      ) {
        if (prop.children) {
          return (
            <UlComponent key={key} isSubMenuOpen={childrenVisible}>
              <SousMenu>
                <NavItem
                  onClick={() => toggleSubMenu(!childrenVisible)}
                  key={key}
                  className={childrenVisible === true ? "ap_mnDAle" : ""}
                >
                  <NavLink
                  // onClick={() => toggleSubMenu()}
                  // onClick={() => props.toggled === false ? props.changeSidebarVisibility() : null}
                  >
                    <prop.icon />
                    &nbsp;
                    {props.toggled === true ? null : prop.name + " "}{" "}
                    <FontAwesomeIcon
                      style={{
                        position: "absolute",
                        top: "15px",
                        right: 20,
                      }}
                      icon={childrenVisible === true ? faCaretDown : faCaretUp}
                    />
                  </NavLink>
                </NavItem>
                <UlComponent
                  variants={sousMenuVariant}
                  animate={childrenVisible === true ? "visible" : "hidden"}
                >
                  <AnimatePresence>
                    {prop.children.map((childItem, key) => {
                      return (
                        <SousMenu
                          custom={key}
                          variants={itemVariant}
                          animate="visible"
                          key={key}
                        >
                          <NavItem
                            onClick={() => {
                              history.push(`${childItem.path}`);
                            }}
                            key={key}
                            className={
                              props.location.pathname === childItem.path
                                ? "sm_mnDAle"
                                : ""
                            }
                          >
                            <NavLink
                              to={childItem.path}
                              // onClick={() => props.toggled === false ? props.changeSidebarVisibility() : null}
                            >
                              <childItem.icon />
                              &nbsp;
                              {props.toggled === true ? null : childItem.name}
                            </NavLink>
                          </NavItem>
                        </SousMenu>
                      );
                    })}
                  </AnimatePresence>
                </UlComponent>
              </SousMenu>
            </UlComponent>
          );
        } else {
          return (
            <NavItem
              onClick={() => {
                history.push(`${prop.path}`);
                childrenVisible === true && toggleSubMenu(false);
              }}
              key={key}
              className={
                props.location.pathname === prop.path ? "ap_mnDAle" : ""
              }
            >
              {/* <Tooltip>{prop.name}</Tooltip> */}
              <NavLink
                to={prop.path}
                // onClick={() => props.toggled === false ? props.changeSidebarVisibility() : null}
              >
                <prop.icon />
                &nbsp;
                {props.toggled === true ? null : prop.name}
              </NavLink>
            </NavItem>
          );
        }
      }
      return true;
    });
  };
  return (
    <SidebarContainer toggled={props.toggled}>
      {/* <PerfectScrollbar style={{ flex: 1 }} onScrollUp={() => alert("scroll")}> */}
      {/* <HeaderLink to="/tableau-de-bord">
                  <NavbarHeader>
                  <AppLogoIcon src={LogoApp} alt="AppLogo" />&nbsp;
                  {props.toggled === true ? null : <AppLogoName>ERCINN</AppLogoName> }
                  </NavbarHeader>
                </HeaderLink> */}
      {createLinks(props.sidebarRoutes)}
      {/* </PerfectScrollbar> */}
    </SidebarContainer>
  );
};

export default Sidebar;
