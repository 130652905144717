/**
 * @Author FyleGroup / Wilfried Yoro
 * @Description Fichier de configuration des urls/requêtes à la base de données
 * @Date_mise_a_jour Jeudi 23 Mars 2021
 */

/**
 * @API_URL Endpoint global pour acceder aux url en fonction de l'environnement de dev
 */
// http://185.247.119.5/etiquetagefg/back/public/api/
//  const dev_api_url = 'http://185.247.119.5/etiquetagefg/back/public/api';
//  const prod_api_url = 'http://185.247.119.5/etiquetagefg/back/public/api';
//  const dev_api_url = 'http://185.247.119.5/ercinn/back/public/api';
//  const prod_api_url = 'http://185.247.119.5/ercinn/back/public/api';
// const dev_api_url = 'http://185.247.119.5/etiquetagefg/back/public/api';
// const prod_api_url = 'http://185.247.119.5/etiquetagefg/back/public/api';
const dev_api_url = "https://apipat.fyle-group.com/api";
const prod_api_url = "https://apipat.fyle-group.com/api";

export const API_URL =
  process.env.NODE_ENV === "development" ? dev_api_url : prod_api_url;

/**
 * @REQUETES_UTILISATEURS
 */
export const CONNEXION_URL =
  "/utilisateur/connexion"; /* POST email_user, password*/
export const EDIT_PASSWORD_URL =
  "/utilisateur/modifier-mot-de-passe-utilisateur"; /* POST email_user, password*/
export const GET_UTILISATEUR_DATA_URL = "";
export const CREER_UTILISATEUR_URL =
  "/utilisateur/creer-un-nouvelle-utilisateur"; /* POST nom_user, prenom_user, telephone_user, email_user, password_user, civilite_user, photo_user, role_id, fonction_id, service_id, bureau_id*/
export const LISTE_UTILISATEURS_URL =
  "/utilisateur/liste-utilisateurs"; /* GET nom_user, prenom_user*/
export const MODIFIER_UTILISATEUR_URL = "/utilisateur/modifier-un-utilisateur";
export const SUPPRIMER_UTILISATEUR_URL =
  "/utilisateur/supprimer-un-utilisateur";
export const AFFECTER_UTILISATEUR_URL =
  "/utilisateur/affecter-un-utilisateur"; /** POST : affecter_id, bureau_id, user_id */

/**
 * @REQUETES_SERVICES
 */
export const CREER_SERVICE_URL =
  "/service/enregistrer-nouveau-service"; /* POST code_service, libelle_service, user_service_id*/
export const LISTE_SERVICES_URL =
  "/service/liste-services"; /* GET nom_user, prenom_user*/
export const MODIFIER_SERVICE_URL = "/service/modifier-un-service";
export const SUPPRIMER_SERVICE_URL = "/service/supprimer-un-service";

/**
 * @REQUETES_ROLES
 */
export const CREER_ROLE_URL =
  "/role/enregistrer-nouveau-role"; /* POST code_role, libelle_role, user_role_id*/
export const LISTE_ROLES_URL =
  "/role/liste-roles"; /* GET nom_user, prenom_user*/
export const MODIFIER_ROLE_URL = "/role/modifier-un-role";
export const SUPPRIMER_ROLE_URL = "/role/supprimer-un-role";

/**
 * @REQUETES_FONCTIONS
 */
export const CREER_FONCTION_URL =
  "/fonction/enregistrer-nouveau-fonction"; /* POST code_fonction, libelle_fonction, user_fonction_id*/
export const LISTE_FONCTIONS_URL =
  "/fonction/liste-fonctions"; /* GET nom_user, prenom_user*/
export const MODIFIER_FONCTION_URL = "/fonction/modifier-une-fonction";
export const SUPPRIMER_FONCTION_URL = "/fonction/supprimer-une-fonction";

/**
 * @REQUETES_BUREAUX
 */
export const CREER_BUREAU_URL =
  "/bureau/enregistrer-nouveau-bureau"; /* POST code_bureau, libelle_bureau, user_bureau_id*/
export const LISTE_BUREAUX_URL =
  "/bureau/liste-bureaux"; /* GET nom_user, prenom_user*/
export const MODIFIER_BUREAU_URL = "/bureau/modifier-un-bureau";
export const SUPPRIMER_BUREAU_URL = "/bureau/supprimer-un-bureau";

/**
 * @REQUETES_LIEUX
 */
export const CREER_LIEU_URL =
  "/lieu/enregistrer-nouveau-lieu"; /* POST code_lieu, libelle_lieu, user_lieu_id, */
export const LISTE_LIEUX_URL =
  "/lieu/liste-lieux"; /* GET nom_user, prenom_user*/
export const MODIFIER_LIEU_URL = "/lieu/modifier-un-lieu";
export const SUPPRIMER_LIEU_URL = "/lieu/supprimer-un-lieu";

/**
 * @REQUETES_MATERIELS
 */
export const CREER_MATERIEL_URL =
  "/materiel/enregistrer-nouvelle-materiel"; /* POST code_materiel, libelle_materiel, photo_materiel, photo_etiquette_materiel, user_affect_materiel_id, bureau_affect_materiel_id, user_materiel_id */
export const LISTE_MATERIELS_URL =
  "/materiel/liste-materiels"; /* GET /materiel/liste-materiels*/
export const LISTE_MATERIELS_PRINCIPAL_URL =
  "/materiel/liste-principale-materiels"; /* GET /materiel/liste-materiels*/
export const MODIFIER_MATERIEL_URL =
  "/materiel/modifier-un-materiel"; /** POST : materiel_id, code_materiel, libelle_materiel, photo_materiel, photo_etiquette_materiel, user_affect_materiel_id, bureau_affect_materiel_id, user_modif_materiel_id */
export const MODIFIER_MODELE_MATERIEL_URL =
  "/materiel/modifier-un-modele-de-materiel"; /** POST : materiel_id, code_materiel, libelle_materiel, photo_materiel, photo_etiquette_materiel, user_affect_materiel_id, bureau_affect_materiel_id, user_modif_materiel_id */
export const SUPPRIMER_MATERIEL_URL =
  "/materiel/supprimer-un-materiel"; /** POST : materiel_id, user_supprime_materiel_id */
export const SUPPRIMER_MODELE_MATERIEL_URL =
  "/materiel/supprimer-un-modele"; /** POST : materiel_id, user_supprime_materiel_id */
export const AFFECTER_MATERIEL_URL =
  "/materiel/affecter-un-materiel"; /** POST : materiel_id, bureau_affect_id, user_affect_id, user_id */
export const GENERER_ETIQUETTE_MATERIEL_URL =
  "/materiel/generer-etiquette"; /** POST : materiels_id */
export const VERIFICATION_MATERIEL_URL =
  "/materiel/rechercher-un-materiel"; /** POST : materiels_id */
export const MODIFIER_PHOTO_MATERIEL_URL =
  "/materiel/modifier-photo-materiel"; /** POST : materiel_id, photo_materiel, user_modif_materiel_id */
export const MODIFIER_QUANTITE_MATERIEL_PRINCIPAL =
  "/materiel/augmenter-nombre-materiel"; /**  POST : materiel_id, quantite, user_id */
export const RECUPERATION_QR_MATERIEL = "/materiel/code-qr-de-materiel";
export const DESACTIVER_MODELE_MATERIEL = "/materiel/desactiver-un-modele";
export const RECHERCHER_MATERIEL_FILTRE = "/materiel/rechercher-materiel";
export const RECHERCHER_MODELE_MATERIEL_FILTRE =
  "/materiel/recherche-modele-materiel";

/**
 * @REQUETES_CATEGORIES_ET_SOUS_CATEGORIE_MATERIELS
 */
export const CREER_CATEGORIE_URL =
  "/categorie/enregistrer-nouvelle-categorie"; /* POST code_categorie, libelle_categorie, user_categorie_id */
export const MODIFIER_CATEGORIE_URL =
  "/categorie/modifier-une-categorie"; /* POST categorie_id, code_categorie, libelle_categorie, user_categorie_modif_id */
export const SUPPRIMER_CATEGORIE_URL =
  "/categorie/supprimer-une-categorie"; /* POST categorie_id, user_categorie_supprimer_id */
export const LISTE_CATEGORIES_URL = "/categorie/liste-categories";
export const RECHERCHER_CATEGORIE_MATERIEL_FILTRE =
  "/categorie/recherche-categorie"; /* GET nom_user, prenom_user*/

export const CREER_SOUS_CATEGORIE_URL =
  "/sous-categorie/enregistrer-nouvelle-sous-categorie"; /* POST code_sous_cat, libelle_sous_cat, user_sous_cat_id, categorie_id */
export const MODIFIER_SOUS_CATEGORIE_URL =
  "/sous-categorie/modifier-une-sous-categorie"; /* POST sous_cat_id, categorie_id, code_sous_cat, libelle_sous_cat, user_sous_cat_modif_id */
export const SUPPRIMER_SOUS_CATEGORIE_URL =
  "/sous-categorie/supprimer-une-sous-categorie"; /* POST sous_cat_id, categorie_id, code_sous_cat, libelle_sous_cat, user_sous_cat_modif_id */
export const LISTE_SOUS_CATEGORIES_URL =
  "/sous-categorie/liste-sous-categories";
export const RECHERCHER_CATEGORIE_SOUS_MATERIEL_FILTRE =
  "/sous-categorie/recherche-sous-categorie"; /* GET nom_user, prenom_user*/

/**
 * @UPLOADING_AND_OPTIONS
 *
 */
export const UPLOAD_PHOTO_UTLISATEUR_URL =
  "/utilisateur/uploader-photo-utilisateur";
export const UPLOAD_PHOTO_MATERIEL_URL = "/materiel/uploader-photo-materiel";

/**
 * @STATISTIQUES
 *
 */

export const LISTE_STATS_URL = "/materiel/tableau-de-bord";

/**
 * @PHOTO_STORAGE_URL
 */
export const STORAGE_UTILISATEUR_URL =
  process.env.NODE_ENV === "development"
    ? "https://apipat.fyle-group.com/storage/fichier/photo_utilisateur"
    : "https://apipat.fyle-group.com/storage/fichier/photo_utilisateur";
export const STORAGE_MATERIEL_URL =
  process.env.NODE_ENV === "development"
    ? "https://apipat.fyle-group.com/storage/fichier/photo_materiel"
    : "https://apipat.fyle-group.com/storage/fichier/photo_materiel";
export const STORAGE_ETIQUETTE_URL =
  process.env.NODE_ENV === "development"
    ? "https://apipat.fyle-group.com/storage/fichier/photo_materiel_etiquette"
    : "https://apipat.fyle-group.com/storage/fichier/photo_materiel_etiquette";
//  export const STORAGE_UTILISATEUR_URL = process.env.NODE_ENV === 'development' ? 'http://185.247.119.5/ercinn/back/public/storage/fichier/photo_utilisateur' : 'http://185.247.119.5/ercinn/back/public/storage/fichier/photo_utilisateur'
//  export const STORAGE_MATERIEL_URL = process.env.NODE_ENV === 'development' ? 'http://185.247.119.5/ercinn/back/public/storage/fichier/photo_materiel' : 'http://185.247.119.5/ercinn/back/public/storage/fichier/photo_materiel'
//  export const STORAGE_ETIQUETTE_URL = process.env.NODE_ENV === 'development' ? 'http://185.247.119.5/ercinn/back/public/storage/fichier/photo_materiel_etiquette' : 'http://185.247.119.5/ercinn/back/public/storage/fichier/photo_materiel_etiquette'
