import useSWR from "swr"
import { getUserData } from "./authActions"

export default function useUser(){
    const{data, error, mutate } = useSWR('api_user', getUserData)
    return{
        user: data,
        chargement: !data && !error,
        isConnected: typeof localStorage.getItem("logged") !== "undefined" && parseInt(localStorage.getItem("logged")) === 1,
        // isConnected: data && (localStorage.getItem('erc_a_u_d') !== '') ,
        userRole : typeof data != 'undefined' ? data.role_id : null,
        erreur: error,
        mutate
    }
}