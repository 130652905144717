import axios from "axios";
import moment from 'moment'
import { osName, osVersion, browserName, browserVersion } from 'react-device-detect'

let latitude = '2.55888';
let longitude = '-202555';
let ip=''
let navigateur=''
let systeme = ''

async function initializeApp(token){
    ip = '127.0.0.1'
    navigateur = browserName+''+browserVersion
    systeme = osName+''+osVersion
    //Recupérer les differentes informations de l'appareil
    if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
           latitude = position.coords.latitude
           longitude = position.coords.longitude
        })
    }
}

//Recupération du cookie
export function getCookie(cookieName){
    
    const data = localStorage.getItem(cookieName)
    if(typeof data !== 'undefined' && data !== '' && data!== null){
        return data
    }else{
        return false
    }
    // let cookieValue
    // if(typeof document.cookie !== 'undefined' && document.cookie !== ''){
    //     cookieValue = document.cookie.split('; ').find(row => row.startsWith(cookieName)).split('=')[1];
        
    //     return cookieValue
    // }else{
    //     return false
    // }
}

export async function setAxiosAuthorization(ercin_auth_token){
    axios.defaults.headers["authorization"] = `Bearer ${ercin_auth_token}`;
}

export async function setHistoriqueAuthorization(ercin_user_token){
    await initializeApp()
    axios.defaults.headers["historique"] = `${ercin_user_token} ${longitude} ${latitude} ${ip} ${navigateur} ${systeme}`;
    return true;
}

//Convertion DataUrl to Image
export async function urltoFile(url, filename, mimeType){
    return (await fetch(url)
        .then(function(res){return res.arrayBuffer();})
        .then(function(buf){return new File([buf], filename,{type:mimeType});})
    );
}

export const checkSessionActiveTime = () => {
    //recupération du _SESS_EXP_TIME si disponible
    const lastConnexionTime = localStorage.getItem('_SESS_EXP_TIME')
    if(typeof lastConnexionTime !== 'undefined' && lastConnexionTime){
        const currentTime =  moment(new Date()).unix() - lastConnexionTime
        return parseInt(currentTime)
    }else{
        return -1
    }
}