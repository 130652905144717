import React from "react";
import { ShieldOff } from "react-feather";
import { Button } from "semantic-ui-react";
import styled from "styled-components";

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #fff;

  background: linear-gradient(39deg, #00b7ff, #008cff);
`;
const AccessDenied = ({ history }) => {
  return (
    <ErrorContainer>
        <ShieldOff size={82} />
      <h1 style={{textAlign: "center", textTransform: "uppercase"}}>
        Vous n'avez pas les autorisations nécessaires <br/> Pour accéder à cette page
      </h1>
      <p>Veuillez contactez votre administrateur</p>
      <Button size="large" color="black" onClick={() => history.goBack()}>
        Retour à la page précédente
      </Button>
    </ErrorContainer>
  );
};

export default AccessDenied;
