import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { LazySpinner } from "../components/Loader/LazySpinner";
import PrivateRoute from "../components/PrivateRoute";
import AuthLayout from "../layout/AuthLayout";
import NotFound from "../pages/errors/NotFound";
import routes from "./routesConfig";

const AppRouter = () => {
  return (
    <Router>
      <Suspense fallback={<LazySpinner />}>
        <Switch>
          {routes.map((routeItem, idx) => {
            if (routeItem.layout === "/auth") {
              return (
                <Route
                  key={idx + 1}
                  name={routeItem.name}
                  path={routeItem.path}
                  exact={true}
                  render={(props) => (
                    <AuthLayout {...props}>
                      <routeItem.component {...props} />
                    </AuthLayout>
                  )}
                />
              );
            } else if (routeItem.layout === "/admin") {
              return (
                <PrivateRoute
                  key={idx + 1}
                  name={routeItem.name}
                  path={routeItem.path}
                  exact={true}
                  layout={routeItem.layout}
                  Component={routeItem.component}
                  permissions={routeItem.permissions}
                />
              );
            } else if (routeItem.layout === "/medium") {
              return (
                <PrivateRoute
                  key={idx + 1}
                  name={routeItem.name}
                  path={routeItem.path}
                  exact={true}
                  layout={routeItem.layout}
                  Component={routeItem.component}
                  permissions={routeItem.permissions}
                />
              );
            }
            return true;
          })}
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default AppRouter;

//   <Route path="/editpassword" render={props => <AuthLayout {...props}/>} />
//   <Route path="/:chemin" render={props => <AdminLayout {...props}/>} />
//   <Route path="/" exact={true} render={props => <AuthLayout {...props}/>} />
