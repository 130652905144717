import { Suspense } from "react";
import { Route } from "react-router-dom";
import useUser from "../data/auth/use-user";
import AdminLayout from "../layout/AdminLayout";
import MediumLayout from "../layout/MediumLayout";
import AccessDenied from "../pages/errors/AccessDenied";
import NotAuthenticated from "../pages/errors/NotAuthenticated";
import { LazySpinner } from "./Loader/LazySpinner";

const PrivateRoute = ({ path, Component, permissions, name, key, layout }) => {
  const { isConnected, userRole } = useUser();
  // console.log(user);
  return isConnected ? (
    <Suspense fallback={<LazySpinner />}>
      {permissions.includes(userRole) ? (
        <>
          {layout === "/admin" ? (
            <Route
              key={key}
              name={name}
              path={path}
              exact={true}
              render={(props) => (
                <AdminLayout {...props}>
                  <Component {...props} />
                </AdminLayout>
              )}
            />
          ) : (
            <Route
              key={key}
              name={name}
              path={path}
              exact={true}
              render={(props) => (
                <MediumLayout {...props}>
                  <Component {...props} />
                </MediumLayout>
              )}
            />
          )}
        </>
      ) : (
        //Case where doesn't have required privilegies
        <Route component={AccessDenied} />
      )}
    </Suspense>
  ) : (
    //case the user is not authenticated
    <Route component={NotAuthenticated} />
  );
};

// return isConnected ? (
//     <Route path={path} component={component} key={key} />
// ) : (
//     <Redirect to="/" />
// );

export default PrivateRoute;
